import React from "react";

import { Menu, useResourceDefinitions } from "react-admin";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

const LayoutMenu = () => {
  const resources = useResourceDefinitions();

  return (
    <Menu
      sx={{
        marginTop: 0,
      }}
    >
      {Object.keys(resources).map((name) => {
        if (name === "events") {
          return <Menu.ResourceItem name={name} key={name} />;
        }
        return <Menu.ResourceItem name={name} key={name} />;
      })}
      <Menu.Item to="/qrcheck" primaryText="Scan QR" leftIcon={<QrCodeScannerIcon />} />
    </Menu>
  );
};

export default LayoutMenu;
